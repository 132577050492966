export const environment = {
    production: true,
    dev: false,
    forceLogLevelDebug: false,
    isRosEnabled: false,
    forceImanageEnabled: false,
    authIntercept: true,
    basicAuth: false,
    authGuard: true,
    api: 'api/titles',
    cloudFunctions: 'https://europe-west2-quick-howl-253913.cloudfunctions.net',
    signInPopup: true,
    googleApiKey: 'AIzaSyDa9tu-mrQymrlWRz7j8iY46nCiamKwEmQ',
    firebase: {
        apiKey: 'AIzaSyCaW0u_cdXgoqwB21iAe2E8uGyCY2e10Hw',
        authDomain: 'quick-howl-253913.firebaseapp.com',
    },
    enableIntercom: true,
    intercomModule: {
        appId: 'j5hpcgz2', // from your Intercom config
        updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    },
    intercomData: {
        appId: 'j5hpcgz2',
        apiBase: 'https://api-iam.eu.intercom.io',
        // Supports all optional configuration.
        widget: {},
        customLauncherSelector: '#support',
        hideDefaultLauncher: true,
        userHash: null,
    },
    enableAddSchedules: true,
    sentryData: {
        dsn: 'https://428ab36119ec4069ab8d346c50a8eff8@o4504039649968128.ingest.sentry.io/4504039659274240',
        isEnabled: true,
        isTracingEnabled: false,
        tracesSampleRate: 0.3
    },
    imanageConfig: {
        imanageUrl: '',
        clientId: '',
        redirectUri: ''
    },
    mapSearch: {
        styleUrl: '/assets/map-settings/OS_VTS_3857_Light_Prod.json',
        stylesUrlOrigin: 'https://app.avail.ai',
    },
    isTitleDocumentsReorderingEnabled: false,
    isWhat3WordsSearchEnabled: true,
};
